.profile {
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &-title {
        color: var(--smoke-white)
    }
    &-label {
        font-weight: 700;
        color: black;
        font-size: 18px;
    }
    &-desc {
        font-size: 20px;
        color: var(--light-grey);
        margin: 0;
    }
    &-icon {
        height: 150px;
        width: 150px;
        margin: 40px 0 50px 0;
        filter: brightness(70%);
    }
    &-col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-row {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-edit {
        height: 50px;
        width: 50px;
        padding: 10px;
        border: solid 4px transparent;
        margin-left: 20px;
        &:hover {
            border: solid 4px black;
            border-radius: 12px;
            cursor: pointer;
        }
    }
    &-coin {
        height: 30px;
        width: 30px;
        margin-left: 5px;
        filter: invert(0.9);
    }
    &-mb {
        margin-bottom: 50px;
    }
    &-delete {
        height: 60px;
        width: 60px;
        filter: brightness(1);
        padding: 5px;
        &:hover {
            filter: brightness(0.7);
            cursor: pointer;
            border-radius: 12px;
        }
    }
}