.album {
    &-title {
        color: var(--smoke-white);
    }
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &-row {
        display: flex;
        justify-content: space-between;
    }
    &-wrapper {
        display: flex;
    }
    &-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 200px;
    }
}