$background-dark: #333;
$cancelled-color: #ff4d4d;
$completed-color: #4caf50;
$pending-color: #ffc107;
$sent-color: #9c27b0;
$received-color: #00bcd4;
$text-color: #fff;

@mixin row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.trades {
    &-container {
        justify-content: center;
        display: flex;
        align-items: center;
        white-space: nowrap;
        flex-wrap: wrap;
    }
    &-row {
        @include row;
        &-names {
            @include row;
            justify-content: space-around;
            max-width: 1500px;
        }
    }
    &-side-left {
        width: 40%;
        max-width: 500px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &-side-right {
        width: 40%;
        max-width: 500px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &-side-middle {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-icon {
        height: 100px;
        width: 100px;
        padding: 0px;
        margin: 0px 20px;
        @media (max-width: 600px) {
            height: 75px;
            width: 75px;
        }
    }
    &-icon-2 {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        filter: invert(1);
    }
    &-title {
        color: #D3D3D3;
        margin-bottom: 30px;
    }
    &-subtitle {
        color: #eee2a2;
        font-size: 20px;
    }
    &-wrapper {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &-btn {
        margin: 20px 10px 20px 10px;
        width: 75px;
    }
    &-border {
        border-right: 4px solid var(--smoke-white);
        padding-right: 10px;;     
    }
    &-separator {
        width: 80%;
        border: solid 2px;
        color: var(--smoke-white);
    }
    &-no-items {
        color: #eee2a2;
        font-size: 25px;
        margin-top: 50px;
    }
    &-no-items-2 {
        color: var(--red);
        font-size: 25px;
        margin-top: 50px;
    }
    &-tag {
        &-pending {
            margin-top: 20px;
            width: 100px;
            height: 40px;
            border: 1px solid #eee2a2;
            border-radius: 10px;
            background-color: #ffc107;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-cancelled {
            margin-top: 20px;
            width: 100px;
            height: 40px;
            border: 1px solid #eee2a2;
            border-radius: 10px;
            background-color: #ff4d4d;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-completed {
            margin-top: 20px;
            width: 100px;
            height: 40px;
            border: 1px solid #eee2a2;
            border-radius: 10px;
            background-color: #4caf50 ;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.step {
    &-container {
        display: flex;            
        justify-content: center;   
        align-items: center;
    }
    &-search {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            height: 400px;
        }
        &-bar {
            width: 100%; 
            padding: 8px;
            margin-top: 25px;
            font-size: 16px;
            border-radius: 10px;
            border: solid 5px var(--red);
            outline: none; 
            &:focus {
                border: solid 5px var(--red);
            }
        }
        &-list {
            left: 0;
            width: 100%;
            border: 1px solid var(--red);
            background-color: var(--light-grey);
            list-style-type: none;
            margin: 0;
            padding: 0;
            border-radius: 10px;
        }
        &-item {
            padding: 8px;
            cursor: pointer;
            border-radius: 10px;
            &:hover {
                background-color: var(--smoke-white);
            }
        }
        &-text {
            margin-top: 40px;
        }
        &-selected {
            margin-top: 10px;
            padding: 5px;
            background-color: var(--orange);
            border: solid 3px var(--red);
            width: fit-content;
            border-radius: 10px;
        }
        &-warning {
            color: red;
            margin-top: 10px;
        }
    }
    &-cards{
        &-container {
            margin: 10px 0;
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 400px;
            overflow-y: auto; 
            flex-wrap: wrap;
        }
    }
}

.minicard {
    height: 200px;
    width: 150px;
    border-radius: 10px;
    color: var(--smoke-white);
    font-size: 14px;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 20px 30px 0 10px;
    position: relative;
    img {
        height: 125px;
        width: 110px;
    }
    &-checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
}

.tradecard {
    height: 150px;
    width: 100px;
    border-radius: 5px;
    color: var(--smoke-white);
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0 10px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
    img {
        height: 90px;
        width: 80px;
    }
    &-checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
}

.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    color: $text-color;
    transition: background-color 0.3s ease;
    height: 50px;
    width: 150px;

    &.active {
        border: 2px solid $text-color;
        opacity: 1;
    }

    &.inactive {
        border: 2px solid lighten($background-dark, 10%);
        opacity: 0.5;
        color: black;
    }

    &.cancelled {
        background-color: $cancelled-color;

        &.inactive {
        background-color: lighten($cancelled-color, 10%);
        }
    }

    &.completed {
        background-color: $completed-color;

        &.inactive {
        background-color: lighten($completed-color, 10%);
        }
    }

    &.pending {
        background-color: $pending-color;

        &.inactive {
        background-color: lighten($pending-color, 10%);
        }
    }

    &.sent {
        background-color: $sent-color;

        &.inactive {
        background-color: lighten($sent-color, 10%);
        }
    }

    &.received {
        background-color: $received-color;

        &.inactive {
        background-color: lighten($received-color, 10%);
        }
    }
}

.no-items-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
