.shop {
    &-row {
        display: flex;
        flex-direction: start;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    &-title {
        color: var(--smoke-white)
    }
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    &-header {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        width: 95%;
    }
    &-coin {
        color: var(--smoke-white);
        font-size: 30px;
        font-weight: 500;
    }
    &-icon {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        filter: invert(1);
    }
}

.mt {
    margin-top: 100px;
}

.tr-position {
    position: absolute;
    right: 20px;
}