.pack {
    &-container {
        height: 150px;
        width: 110px;
        border-radius: 10px;
        border: 3px solid var(--dark-red);
        padding: 5px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
    &-amount {
        margin-top: 10px;
        font-weight: 800;
    }
    &-desc {
        margin-top: 0px;
        font-weight: 800;
    }
    &-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 40px 40px 0px 0;
    }
    &-icon {
        height: 25px;
        width: 25px;
        margin-left: 5px
    }
    &-icon-2 {
        height: 40px;
        width: 40px;
    }
    &-price {
        font-size: 16px;
        color: black;
        font-weight: 600;
    }
    &-btn {
        background-color: #1E90FF;
    }
    &-input {
        border: 3px solid var(--dark-red);
        background-color: var(--smoke-white);
        border-radius: 10px;
        color: var(--smoke-white);
        height: 40px;
        width: 300px;
        padding: 5px;
    }
    &-input-small {
        border: 3px solid var(--dark-red);
        background-color: var(--smoke-white);
        border-radius: 10px;
        color: var(--smoke-white);
        height: 40px;
        width: 200px;
        padding: 5px;
        @media (min-width: 767px) and (max-width: 993px) {
            width: 150px;
        }
    }
    &-line {
        width: 75%;
        margin-top: 8px;
    }
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0;
    }
}