.card {
    &-container {
        height: 375px;
        width: 250px;
        background-color: var(--dark-red);
        border-radius: 10px;
        border: 5px solid var(--light-grey);
        margin: 35px 35px 0px 0;
        padding: 4px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;

    }
    &-name {
        margin-top: 10px;
        color: var(--smoke-white);
        height: 48px;
    }
    &-desc {
        color: #eee2a2;
        height: 67px;
        margin-top: 0px;
        margin-bottom: 5px;
        max-height: 100px;
        text-overflow: ellipsis; 
        overflow: hidden;
    }
    &-image {
        width: 175px;
        min-height: 175px;
        max-height: 175px;
    }
    &-icon {
        cursor: pointer;
        height: 40px;
        width: 40px;
        &:hover {
            filter: invert(1)
        }
    }
}

.details {
    &-image {
        width: 300px;
        min-height: 300px;
        max-height: 300px;
        margin: 30px 0;
    }
    &-label {
        font-weight: 700;
        color: black;
        font-size: 20px;
        margin-top: 30px;
    }
    &-desc {
        font-size: 15px;
        color: var(--light-grey);
        margin: 0;
    }
    &-link {
        font-size: 15px;
        color: var(--dark-red) ; 
        margin: 0;
        &:hover {
            color:var(--smoke-white)
        }
    }
}

.no-underline {
    text-decoration: none;
}