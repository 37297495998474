body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-grey: #202020;
  --grey: #515050;
  --light-grey: #D3D3D3;
  --smoke-white: #F0F0F0;
  --primary-green: #2e8f07;
  --secondary-green: #50f011;
  --red: #B22222;
  --dark-red: #8B0000;
  --orange: #cc6600;
}
